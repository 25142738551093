<template>
  <div v-if="!isCreateMode">
    <el-form
      ref="form"
      v-loading="loading"
      :label-position="$vars.forms.label_position"
      :label-width="$vars.forms.label_width"
      :model="item"
      :name="$options.name"
      :size="$vars.sizes.form"
      :disabled="disabled"
      class="camera-info-form"
    >
      <el-form-item v-if="item.id" :label="$tf('common.id')">
        <span name="id">{{ item.id }}</span>
      </el-form-item>

      <el-form-item v-if="item.external_detector_token" :label="$tf('external_detector_token')">
        {{ item.external_detector_token }}<br />
        <copy-to-clipboard-button :text="item.external_detector_token" />
      </el-form-item>

      <el-form-item v-if="item.screenshot" :label="$tf('common.image')">
        <camera-screenshot
          :displayWidth="'100%'"
          :formContent="formContent"
          :height="-1"
          :screenshot="item.screenshot"
          :width="-1"
          name="camera-screenshot"
          refresh
        ></camera-screenshot>
      </el-form-item>

      <el-form-item v-if="item.statistic" :label="$tf('common.status')">
        <div name="status">
          <camera-status :color="item.state_color" :colorDesc="item.state_color_desc"></camera-status>
          <span class="camera-state">{{ item.state | cameraStateFormat }}</span
          ><br />
          <div class="camera-info-form__statistic">
            <div>
              <div>
                <span>{{ $tf('process_duration') }}</span>
                <span>{{ $filters.tShortDuration(item.statistic.processed_duration) }}</span>
              </div>
              <div>
                <span>{{ $tf('starts_of_job') }}</span>
                <span>{{ item.statistic.job_starts }}</span>
              </div>
              <div>
                <span>{{ $tf('fps') }}</span> <span>{{ Math.round(item.statistic.processing_fps) }}</span>
              </div>
              <div>
                <span>{{ $tf('decoding_errors') }}</span>
                <span>{{ item.statistic.decoding_soft_errors }}</span>
              </div>
            </div>

            <div>
              <div>
                <span>{{ $tf('posted_objects') }}</span>
                <span>{{ item.statistic.faces_posted }}</span>
              </div>
              <div>
                <span>{{ $tf('failed_objects') }}</span>
                <span>{{ item.statistic.faces_failed }}</span>
              </div>
              <div>
                <span>{{ $tf('not_posted_objects') }}</span>
                <span>{{ item.statistic.faces_not_posted }}</span>
              </div>
            </div>

            <div>
              <div>
                <span>{{ $tf('processed_frames') }}</span>
                <span>{{ item.statistic.frames_processed }}</span>
              </div>
              <div>
                <span>{{ $tf('dropped_frames') }}</span>
                <span>{{ item.statistic.frames_dropped }}</span>
              </div>
              <div>
                <span>{{ $tf('skipped_imotion_frames') }}</span>
                <span>{{ item.statistic.frames_imotion_skipped }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import CameraStatus from '../camera-status.vue';
import CameraScreenshot from '../camera-screenshot.vue';
import CopyToClipboardButton from '@/components/common/copyToClipboardButton';

export default {
  name: 'camera-info',
  props: {
    state: Object,
    item: Object,
    loading: Boolean,
    isCreateMode: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CopyToClipboardButton,
    CameraStatus,
    CameraScreenshot
  },
  data: function () {
    return {
      formContent: {
        image: null
      }
    };
  },
  created() {
    this.loadStats.bind(this);
    this.updateStatusInterval = setInterval(this.loadStats, 10000);
  },
  beforeDestroy() {
    clearInterval(this.updateStatusInterval);
  },
  methods: {
    loadStats() {
      let id = this.$route.params.id;
      if (id) {
        this.$store.dispatch(this.state.Action.Get, { id: decodeURIComponent(id) }).then((v) => {
          if (this.item) {
            Object.assign(this.item, _.pick(this.state.itemHandler(v), ['health_status', 'statistic', 'state', 'state_color', 'state_color_desc']));
          }
        });
      }
    }
  }
};
</script>

<style>
.camera-info-form__statistic {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 28px;
  grid-row-gap: 18px;
  margin-top: 10px;
  line-height: 1.3rem;
}

.camera-info-form__statistic > div > div {
  display: flex;
  justify-content: space-between;
}
</style>
