<template>
  <form-layout>
    <template slot="back-button">
      <el-button name="back-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('back') }}</el-button>
    </template>

    <span slot="title">{{ title }}</span>

    <div slot="left-tabs">
      <common-tabs v-model="checkedTab" :items="verticalTabs" :vertical="true" class="header-tabs" :isCreateMode="isCreateMode"></common-tabs>
    </div>

    <div slot="left-controls">
      <controls
        :state="state"
        :item="item"
        :isCreateMode="isCreateMode"
        :enabledSave="enabledSave"
        @save="saveHandler"
        @delete="deleteHandler"
        @restart="restartHandler"
        @reset="resetHandler"
        @cancel="cancelHandler"
        @reload="load"
      ></controls>
    </div>

    <div slot="body">
      <div>
        <div v-for="tab in tabs" :key="tab.name">
          <component
            :is="tab.component"
            v-if="tab.name === checkedTab"
            :item="item"
            :state="state"
            :loading="loading"
            :image="screenshotDataUrl"
            :loadImage="() => loadScreenshot(true)"
            :defaultParameters="defaultParameters"
            :isCreateMode="isCreateMode"
            :disabled="disabled"
            @getForm="getForm"
          ></component>
        </div>
      </div>
    </div>
  </form-layout>
</template>

<script>
import FormLayout from '../../common/form.layout';
import Controls from './controls';
import CameraInfoTab from './camera_info';
import SettingsTab from './settings';
import GeopositionTab from './geoposition';
import AnalyticsTab from './analytics';
import RoiTab from './roi';
import _ from '@/apps/common/lodash';
import axios from 'axios';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';

const Tabs = {
  camera_info: {
    name: 'camera_info',
    component: 'CameraInfoTab'
  },
  settings: {
    name: 'settings',
    component: 'SettingsTab'
  },
  geoposition: {
    name: 'geoposition',
    component: 'GeopositionTab',
    notAvailableFor: ['external_detector']
  },
  analytics: {
    name: 'analytics',
    component: 'AnalyticsTab',
    notAvailableFor: ['external_detector']
  },
  roi: {
    name: 'roi',
    component: 'RoiTab',
    notAvailableFor: ['external_detector']
  }
};

export default {
  components: {
    FormLayout,
    Controls,
    CameraInfoTab,
    SettingsTab,
    GeopositionTab,
    AnalyticsTab,
    RoiTab
  },
  name: 'camera-form',
  data: function () {
    return {
      tabs: Tabs,
      checkedTab: '',
      screenshotDataUrl: null,
      item: {},
      itemCopy: {},
      loading: false,
      saving: false,
      forms: [],
      disabled: false,
      defaultParameters: { stream_settings: {} },
      hasError: false
    };
  },
  computed: {
    verticalTabs() {
      return Object.keys(this.tabs).map((tab) => {
        const itemHasExternalDetector = this.item.external_detector && this.tabs[tab].notAvailableFor?.includes('external_detector');
        if ((this.isCreateMode && (tab === Tabs.camera_info.name || tab === Tabs.roi.name)) || itemHasExternalDetector) {
          return false;
        }
        return { name: tab, i18n: tab };
      });
    },
    title() {
      return this.$tfo(this.isCreateMode ? 'add | camera,, 1' : 'edit | camera,, 1');
    },
    isCreateMode() {
      return !this.$route.params.id;
    },
    state() {
      return this.$store.state.cameras;
    },
    enabledObjects() {
      return this.$store.getters.enabledObjects;
    },
    cameraScreenshotUrl() {
      const id = this.$route.params.id;
      return this.$store.state.config.server.url + `cameras/${id}/screenshot/`;
    },
    enabledSave() {
      const item = {
        ...this.item,
        health_status: null,
        statistic: null,
        state: null,
        state_color: null,
        state_color_desc: null
      };
      const itemCopy = {
        ...this.itemCopy,
        health_status: null,
        statistic: null,
        state: null,
        state_color: null,
        state_color_desc: null
      };
      const diff = _.differenceOf(item, itemCopy);
      return this.saving ? false : !!Object.keys(diff).length;
    }
  },
  watch: {
    '$route.params.id': function (v, p) {
      v && this.load();
    }
  },
  created() {
    this.checkInitialTab();
    this.loadDefaultParameters();
    this.load();
  },
  methods: {
    setDisabled(v) {
      this.disabled = v;
    },
    getForm(value) {
      this.forms.push(value);
    },
    checkInitialTab() {
      const routeTab = this.$route.params.tab;
      if (routeTab && Tabs[routeTab]) {
        this.checkedTab = Tabs[routeTab].name;
      } else {
        this.checkedTab = this.isCreateMode ? Tabs.settings.name : Tabs.camera_info.name;
      }
    },
    load() {
      const id = this.$route.params.id;
      this.item = this.getItemWithEnabledDetectors(this.state.item.empty);
      this.itemCopy = _.cloneDeep(this.item);
      if (!id) return;

      this.loading = true;
      this.$store
        .dispatch(this.state.Action.Get, { id: decodeURIComponent(id) })
        .then((v) => {
          this.item = v;
          this.itemCopy = _.cloneDeep(v);
          this.loadScreenshot();
        })
        .catch((e) => {
          !this.hasError && this.errorNotify(e);
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadScreenshot(force) {
      if (this.screenshotDataUrl && !force) return;
      return axios({
        url: this.cameraScreenshotUrl,
        responseType: 'blob',
        method: 'POST',
        headers: {
          Authorization: 'Token ' + encodeURIComponent(this.$store.state.app.token)
        }
      })
        .then((v) => {
          if (v.data && v.data.size) {
            this.screenshotDataUrl = URL.createObjectURL(v.data);
          } else {
            throw new Error('[image:loaded] empty data error');
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    resetHandler() {
      this.setItemDefaultParameters();
    },
    loadDefaultParameters() {
      this.$store
        .dispatch(this.state.Action.GetDefaultParameters, this.item)
        .then((v) => {
          this.defaultParameters = v;
          if (this.isCreateMode) this.setItemDefaultParameters();
        })
        .catch((e) => {
          this.setDisabled(true);
          !this.hasError && this.errorNotify(e);
          this.hasError = true;
        });
    },
    setItemDefaultParameters() {
      const { stream_settings } = this.getItemWithEnabledDetectors(this.defaultParameters);
      this.item.stream_settings = stream_settings;
    },
    saveHandler() {
      Promise.all(this.forms.map((form) => form && form.validate()))
        .then(() => {
          this.validateHandler(true);
        })
        .catch((e) => {
          this.errorNotify(this.$tt('error.form.validation'), 1000);
        });
    },
    cancelHandler() {
      this.$router.backTo({ path: '/cameras/filter/limit=' + this.state.filter.current.limit });
    },
    restartHandler() {
      this.$store
        .dispatch('restartCamera', this.item)
        .then(() => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$emit('reload');
        })
        .catch((e) => {
          this.errorNotify(e);
        });
    },
    deleteHandler() {
      this.$store
        .dispatch(this.state.Action.Delete, this.item)
        .then(() => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$router.push({ path: '/cameras/filter/limit=10' });
        })
        .catch((e) => {
          this.errorNotify(e);
        });
    },
    validateHandler(valid) {
      if (!valid) return;
      const action = this.isCreateMode ? this.state.Action.Create : this.state.Action.Update;
      this.saving = true;

      return this.$store
        .dispatch(action, this.item)
        .then((v) => {
          this.saving = false;
          this.item.id = v.id;
          this.itemCopy = _.cloneDeep(this.item);
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$router.push({ path: `/cameras/${v.id}/` }, () => {
            if (action === this.state.Action.Create) {
              this.checkedTab = Tabs.camera_info.name;
            }
          });
        })
        .catch((e) => {
          this.errorNotify(e);
        });
    },
    getItemWithEnabledDetectors(item) {
      const disabledObjects = this.$store.getters.disabledObjects;
      const result = _.cloneDeep(item);
      disabledObjects.forEach((v) => {
        result.stream_settings.detectors[ObjectsTypeSingleForm[v]] = null;
      });
      return result;
    },
    errorNotify(e, duration = 0) {
      this.$notify({ duration, message: this.$createElement('message-box', { props: { e: e } }) });
    }
  }
};
</script>
