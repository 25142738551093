<template>
  <div>
    <common-tabs v-model="checkedTab" :items="tabs" class="header-tabs"></common-tabs>

    <div v-for="tab in tabItems" :key="tab.name">
      <component :is="tab.component" v-if="tab.name === checkedTab" v-bind="$props" @getForm="getForm"></component>
    </div>
  </div>
</template>

<script>
import MainTab from './settings/main';
import AdvancedTab from './settings/advanced';
import StreamSettingsRot from './settings/rot';

const Tabs = {
  main: {
    name: 'main',
    component: 'MainTab',
    create: true
  },
  advanced: {
    name: 'advanced',
    component: 'AdvancedTab',
    create: true,
    notAvailableFor: ['external_detector']
  },
  rot: {
    name: 'rot',
    component: 'StreamSettingsRot',
    create: false,
    notAvailableFor: ['external_detector']
  }
};

export default {
  name: 'settings',
  components: {
    MainTab,
    AdvancedTab,
    StreamSettingsRot
  },
  props: {
    state: Object,
    item: Object,
    image: String,
    loadImage: Function,
    isCreateMode: Boolean,
    defaultParameters: Object,
    loading: Boolean,
    availableObjects: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      tabItems: Tabs,
      checkedTab: Tabs.main.name,
      forms: []
    };
  },
  computed: {
    tabs() {
      return Object.keys(Tabs)
        .map((tab) => {
          const tabObject = Tabs[tab],
            itemHasExternalDetector = this.item.external_detector && tabObject.notAvailableFor?.includes('external_detector'),
            showTab = !itemHasExternalDetector && (!this.isCreateMode || (this.isCreateMode && tabObject.create));
          return showTab ? { name: tab, i18n: tab } : null;
        })
        .filter((v) => !!v);
    }
  },
  methods: {
    getForm(v) {
      this.$emit('getForm', v);
    }
  }
};
</script>

<style lang="stylus">
.camera-advanced-form {
  &-header {
    display: inline-block;
    margin-bottom: 0.625rem;
  }

  &-transformation {
    width: 7rem;

    &__current {
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
    }
  }
}
</style>
