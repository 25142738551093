<template>
  <div class="controls">
    <el-button name="apply-changes-btn" type="primary" @click="() => $emit('save')" :disabled="!enabledSave">{{ $tf('apply_changes') }} </el-button>

    <el-dropdown v-if="!isCreateMode" @command="handleCommand" :hide-timeout="300">
      <el-button>...</el-button>
      <el-dropdown-menu slot="dropdown" size="small">
        <el-dropdown-item command="restart">{{ $tfo('restart') }}</el-dropdown-item>
        <el-dropdown-item command="reset">{{ $tfo('reset | parameters') }}</el-dropdown-item>
        <el-dropdown-item command="delete">{{ $tf('delete') }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'controls',
  inheritAttrs: true,
  props: {
    state: Object,
    item: Object,
    isCreateMode: Boolean,
    enabledSave: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async handleCommand(v) {
      this.$emit(v);
    }
  }
};
</script>

<style>
.controls {
  display: flex;
  flex-direction: column;
}
.controls button {
  margin: 10px 0 !important;
}
</style>
