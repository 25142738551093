<template>
  <el-form
    v-loading="loading"
    :size="$vars.sizes.form"
    :label-position="$vars.forms.label_position"
    :labelWidth="$vars.forms.label_width"
    :name="$options.name"
    :rules="rules"
    :model="item"
    :disabled="disabled"
    ref="form"
    class="camera-form"
  >
    <el-form-item :label="$tf('name')" prop="name">
      <el-input name="name" v-model="item.name"></el-input>
    </el-form-item>

    <el-form-item :label="$tf('group')" prop="group">
      <el-select name="group" v-model="item.group" :placeholder="$tf('common.select')">
        <el-option
          v-for="listItem in $store.getters.cameraGroupsWithoutSpecial"
          :key="listItem.id"
          :value="listItem.id"
          :label="$filters.shortString(listItem.name)"
        >
        </el-option>
      </el-select>
      <span class="compact-buttons">
        <el-button size="mini" circle icon="el-icon-refresh" @click="loadCameraGroups" />
        <el-button size="mini" circle icon="el-icon-plus" @click="addCameraGroup" />
      </span>
    </el-form-item>

    <el-form-item :label="$tf('connection_type')" prop="connection-type">
      <el-radio-group v-model="connectionType" class="connection-type">
        <el-radio-button label="stream" :disabled="disabledChangeEdge">{{ $tf('stream') }}</el-radio-button>
        <el-radio-button label="onvif" :disabled="disabledChangeEdge">{{ $tf('onvif') }}</el-radio-button>
        <el-radio-button label="external_detector" :disabled="disabledChangeType">{{ $tf('external_detector') }}</el-radio-button>
      </el-radio-group>

      <div v-if="connectionType === 'stream'">
        <el-form-item :label="$tf('stream_url')" prop="url">
          <el-input name="url" v-model="item.url" />
        </el-form-item>
      </div>

      <div v-if="connectionType === 'onvif'">
        <el-form-item class="onvif-button">
          <el-button type="primary" @click="loadOnvifCameras">{{ $tf('load_from_device') }}</el-button>
        </el-form-item>
        <el-dialog :title="$tf('onvif_dialog_title')" :visible.sync="$store.state.dialog.onvif.enabled" :modal="true" class="el-dialog--large">
          <el-table name="cameras-table" class="cameras-table" stripe :data="onvifState.items" row-key="id" v-loading="onvifState.loading">
            <el-table-column prop="Manufacturer" :label="$tf('onvif_brand')"></el-table-column>
            <el-table-column prop="Model" :label="$tf('onvif_model')"></el-table-column>
            <el-table-column prop="host" :label="$tf('onvif_ip_address')"></el-table-column>
            <el-table-column prop="port" :label="$tf('onvif_port')"></el-table-column>
            <el-table-column>
              <template slot-scope="{ row }">
                <span v-if="isAdded(row)">{{ $tf('onvif_added') }}</span>
                <el-button v-else @click.native.prevent="addOnvifInfo(row)" size="small">
                  {{ $tf('onvif_add_button') }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
        <template v-if="extendedItem.onvif_camera">
          <el-form-item :label="$tf('onvif_brand')" prop="Brand">
            <el-input name="Brand" v-model="extendedItem.onvif_camera.Manufacturer" :disabled="true" />
          </el-form-item>
          <el-form-item :label="$tf('onvif_model')" prop="Model">
            <el-input name="Model" v-model="extendedItem.onvif_camera.Model" :disabled="true" />
          </el-form-item>
          <el-form-item :label="$tf('onvif_ip_address')" prop="host">
            <el-input name="host" v-model="extendedItem.onvif_camera.host" :disabled="true" />
          </el-form-item>
          <el-form-item :label="$tf('onvif_port')" prop="port">
            <el-input name="port" v-model="extendedItem.onvif_camera.port" :disabled="true" />
          </el-form-item>
          <template v-if="extendedItem.onvif_camera.need_auth">
            <el-form-item :label="$tf('onvif_username')" prop="login">
              <el-input name="login" v-model="onvifAuth.login" />
            </el-form-item>
            <el-form-item :label="$tf('onvif_password')" prop="password">
              <el-input type="password" name="password" v-model="onvifAuth.password" />
            </el-form-item>
            <el-button :loading="loading" @click.native.prevent="testConnectionOnvif()" type="primary" size="small">
              {{ $tf('onvif_auth_camera') }}
            </el-button>
          </template>
          <el-form-item v-if="hasOnvifStreams" :label="$tf('common.onvif_select_stream')" prop="url">
            <el-select v-model="item.url" name="streams" :placeholder="$tf('common.select')">
              <el-option v-for="(option, key) in extendedItem.onvif_camera.streams" :key="option.Uri" :label="key" :value="option.Uri"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="item.url" :label="$tf('stream_url')" prop="url">
            <el-input name="url" v-model="item.url" :disabled="true" />
          </el-form-item>
        </template>
      </div>
    </el-form-item>

    <el-form-item :label="$tf('common.comment')" prop="comment">
      <el-input name="comment" v-model="item.comment"></el-input>
    </el-form-item>

    <el-form-item prop="active">
      <el-checkbox name="active" v-model="item.active">{{ $tf('common.active,,1') }}</el-checkbox>
    </el-form-item>
  </el-form>
</template>

<script>
import _ from '@/apps/common/lodash';

const baseRules = {
  name: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  url: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  group: [{ required: true, type: 'number', message: 'error.required.field', trigger: 'change' }]
};

export default {
  name: 'main',
  props: {
    state: Object,
    item: Object,
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      formContent: {
        image: null
      },
      extendedItem: {
        onvif_camera: null,
        onvif_epr: null
      },
      parametersEnabled: false,
      parametersValid: false,
      connectionType: 'stream',
      onvifAuth: {
        login: '',
        password: ''
      },
      isOnvifAuth: false,
      rules: this.$applyRuleMessages(baseRules)
    };
  },
  created() {
    this.connectionType = this.item.onvif_camera ? 'onvif' : this.item.external_detector ? 'external_detector' : 'stream';
    this.$store.dispatch(this.$store.state.camera_groups.Action.Get);
    this.loadStats.bind(this);
    this.updateStatusInterval = setInterval(this.loadStats, 10000);
  },
  mounted() {
    this.$emit('getForm', this.$refs.form);
  },
  beforeDestroy() {
    clearInterval(this.updateStatusInterval);
  },
  computed: {
    onvifState() {
      return this.$store.state.onvif_cameras;
    },
    isCorrectAdvanced() {
      return this.advancedEnabled ? this.advancedValid : true;
    },
    thresholdEnabled: {
      get: function () {
        return this.item.threshold > 0;
      },
      set: function (value) {
        const { confidenceThreshold } = this.$store.getters;
        this.$set(this.item, 'threshold', null);
        this.item.threshold = value ? confidenceThreshold : null;
      }
    },
    hasOnvifStreams() {
      const streams = this.extendedItem.onvif_camera?.streams;
      return streams && Object.keys(streams).length > 0;
    },
    disableSubmit() {
      return this.loading || (this.extendedItem.onvif_camera?.need_auth && !this.isOnvifAuth);
    },
    disabledChangeEdge() {
      return !!(this.item.external_detector && this.item.id);
    },
    disabledChangeType() {
      return !!(!this.item.external_detector && this.item.id);
    }
  },
  methods: {
    addCameraGroup() {
      const route = this.$router.resolve({ path: '/camera-groups/create/' });
      window.open(route.href, '_blank');
    },
    loadCameraGroups() {
      this.$store.dispatch(this.$store.state.camera_groups.Action.Get);
    },
    addOnvifInfo(v) {
      this.cleanOnvifInfo(v);
      this.extendedItem.onvif_camera = v;
      this.$store.state.dialog.onvif.enabled = false;
      this.extendedItem.onvif_epr = v.endpoint_reference;
    },
    loadOnvifCameras() {
      this.$store.state.dialog.onvif.enabled = true;
      this.$store.dispatch('getOnvifCameras');
    },
    testConnectionOnvif() {
      this.loading = true;
      this.$store
        .dispatch('authOnvifCameras', { id: this.extendedItem.onvif_camera.id, ...this.onvifAuth })
        .then((response) => {
          Object.assign(this.extendedItem.onvif_camera, response);
          this.isOnvifAuth = true;
        })
        .catch((e) => this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) }))
        .finally(() => {
          this.loading = false;
        });
    },
    isAdded({ id }) {
      return this.extendedItem.onvif_camera && id === this.extendedItem.onvif_camera.id;
    },
    loadStats() {
      const id = this.$route.params.id;
      if (id) {
        this.$store.dispatch(this.state.Action.Get, { id: decodeURIComponent(id) }).then((v) => {
          if (this.item) {
            Object.assign(this.item, _.pick(this.state.itemHandler(v), ['health_status', 'statistic', 'state', 'state_color', 'state_color_desc']));
          }
        });
      }
    },
    cleanOnvifInfo(v) {
      if (this.extendedItem.onvif_camera?.id === v?.id) return;
      this.extendedItem.onvif_camera = null;
      this.extendedItem.onvif_epr = null;
      this.item.url = null;
    }
  },
  watch: {
    connectionType: function (newVal) {
      this.item.external_detector = newVal === 'external_detector';
      if (this.item.external_detector) {
        delete this.item.url;
      } else {
        this.$set(this.item, 'url', null);
      }
      if (newVal !== 'onvif') {
        this.extendedItem.onvif_camera = null;
        this.extendedItem.onvif_epr = null;
      }
    }
  }
};
</script>

<style>
.onvif-button {
  margin-top: 1.56rem;
}
.connection-type {
  margin: 5px 0 20px;
}
</style>
